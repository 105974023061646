<template>
  <div class="fields-projet-setting">
    <div class="header mb-3">
      <div class="style-title-setting-global">
        {{
          $i18n.locale == 'fr'
            ? 'Gestion des champs personnalisés'
            : 'Custom Field Management'
        }}
      </div>
      <v-btn dark color="#5C2DD3" @click.prevent.stop="Drapdownbutton">
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{
          $i18n.locale === 'fr'
            ? 'Ajouter un champ personnalisé'
            : 'Add custom field'
        }}
      </v-btn>
    </div>
    <div
      class="block-menu-users mt-2"
      :class="{
        'pb-3': !computedCustomFieldsMenu || !computedCustomFieldsMenu.length
      }"
    >
      <div
        class="list-tabs style-tabs"
        v-if="computedCustomFieldsMenu && computedCustomFieldsMenu.length"
      >
        <v-tabs
          bg-color="#5C2DD3"
          :hide-slider="false"
          slider-color="#5C2DD3"
          color="#5C2DD3"
          item-color="#5C2DD3"
          v-model="model"
        >
          <v-tab
            v-for="(tab, index) in computedCustomFieldsMenu"
            :key="tab.id"
            :href="'#tab-' + index"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="Menu(tab)"
            :to="tab.url"
          >
            <div class="menu-item">
              {{
                tab.name
                  ? $options.filters.translateName(tab.name, $i18n.locale)
                  : tab.name
              }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
    <!-- ADD_CUSTOM_FIELDS -->
    <v-dialog v-model="ModaladdChamps" max-width="900" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              champsToAdd && selectedlinkButton.name === 'projets'
                ? $i18n.locale === 'fr'
                  ? 'Ajouter un champ pour le projet'
                  : 'Add project field'
                : $i18n.locale === 'fr'
                ? 'Ajouter un champ pour le contact'
                : 'Add contact field'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModaladdChamps')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="ModaladdChamps" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="champsToAdd.name"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                  "
                  dense
                  clearable
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                    })
                  "
                  v-model="champsToAdd.field_type"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                    })
                  "
                  item-text="label"
                  item-value="id"
                  :items="getAllTypescustomFields"
                  outlined
                  return-object
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      $t('msgObligMsg', {
                        msg:
                          $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                      })
                  ]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="visibilityOption(champsToAdd.field_type)">
              <v-col>
                <v-text-field
                  label="Options"
                  dense
                  ref="refInputOption"
                  :disabled="getCustomFieldsLoading"
                  @click:append-outer="addOptionsAdd(champsToAdd)"
                  @click:clear="addOptionsAdd(champsToAdd)"
                  @keyup.enter="addOptionsAdd(champsToAdd)"
                  append-outer-icon="mdi-send"
                  :persistent-placeholder="true"
                  outlined
                  v-model="champsToAdd.nameOption"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (champsToAdd.selectedOption &&
                        champsToAdd.selectedOption.length > 0) ||
                      'Options ' + $t('msgOblg')
                  ]"
                >
                </v-text-field>
                <Container
                  group-name="col"
                  @drop="onCardDropAddCustomFieldOption($event)"
                  drag-class="card-ghost"
                  drop-class="card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                  :lock-axis="'y'"
                  :class="'hello-002'"
                  drag-handle-selector=".column-drag-container "
                >
                  <Draggable
                    v-for="(item, index) in champsToAdd.selectedOption"
                    :key="index"
                  >
                    <v-text-field
                      class="mt-2"
                      :label="'Option ' + `${index + 1}`"
                      dense
                      :disabled="getCustomFieldsLoading"
                      @click:append-outer="addOptionsDelete(item.id)"
                      @click:clear="addOptionsDelete(item.id)"
                      append-outer-icon="mdi-trash-can-outline"
                      :persistent-placeholder="true"
                      outlined
                      v-model="item.name"
                      color="#5C2DD3"
                      required
                      item-color="#5C2DD3"
                      validate-on-blur
                      :rules="[v => !!v || 'Options ' + $t('msgOblg')]"
                    >
                      <template #prepend>
                        <v-icon
                          :class="{
                            'column-drag-container':
                              champsToAdd &&
                              champsToAdd.selectedOption &&
                              champsToAdd.selectedOption.length > 1
                          }"
                          >mdi-drag-horizontal-variant</v-icon
                        >
                      </template>
                    </v-text-field>
                  </Draggable>
                </Container>
              </v-col>
            </v-row>
            <v-row v-if="selectedlinkButton.name === 'projets'">
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  :placeholder="
                    $t('searchMsg', {
                      msg: $i18n.locale === 'fr' ? 'sous type' : 'sub type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="champsToAdd.sub_type"
                  :items="getAllSubTypes"
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :label="$i18n.locale === 'fr' ? 'Sous Type' : 'Sub Type'"
                  item-text="name"
                  dense
                  item-value="name"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $i18n.locale === 'fr' ? 'sous type' : 'sub type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  multiple
                  :placeholder="$t('searchMsg', { msg: $t('category') })"
                  item-color="#5C2DD3"
                  @input="changeCategorieInAddModal($event)"
                  v-model="champsToAdd.section"
                  :items="getListCategorieInTable"
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :label="$t('category')"
                  item-text="name"
                  dense
                  item-value="name"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option: $t('category')
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="champsToAdd.section.length > 0">
              <v-col>
                <div class="pb-3 font-sz-15 bold-500 font-text color-black">
                  {{
                    $i18n.locale == 'fr' ? 'Sous catégories' : 'Sub category'
                  }}
                </div>
                <div
                  v-if="
                    getCustemFieldsSubCategorieList &&
                      getCustemFieldsSubCategorieList.length
                  "
                >
                  <div
                    v-for="(subCat, index) in getCustemFieldsSubCategorieList"
                    :key="subCat.id"
                    class="block-fields"
                  >
                    <v-row class="mt-1">
                      <v-col class="col-ss-categ ml-1" cols="8">
                        <v-checkbox
                          class="input-checkbox msg-error label-check"
                          v-model="champsToAdd.sub_categories[index]"
                          :label="subCat.name"
                          :value="subCat.id"
                          color="#5C2DD3"
                          hide-details
                        >
                        </v-checkbox
                      ></v-col>

                      <v-col class="col-ss-categ mr-3">
                        <v-switch
                          class="input-checkbox switch-bottom label-switch float-right"
                          :label="
                            $i18n.locale === 'fr'
                              ? 'Champ entête'
                              : 'Header field'
                          "
                          color="#5C2DD3"
                          v-model="champsToAdd.sub_categories_entet[index]"
                        >
                        </v-switch>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div
                  v-if="
                    (!getCustemFieldsSubCategorieList ||
                      !getCustemFieldsSubCategorieList.length) &&
                      !getCustomFieldsLoading
                  "
                  class="color-crm font-text font-sz-12 text-center"
                >
                  {{ $t('aucun') }} {{ $t('sous_categories') }}
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCustomFieldsLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCustomFieldsError" class="error-msg">
              <ul v-if="Array.isArray(getCustomFieldsError)">
                <li v-for="(e, index) in getCustomFieldsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCustomFieldsError }}</span>
            </div>
          </div>
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitAddCustomFields"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModaladdChamps')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD_CUSTOM_FIELDS_RESOURCE -->
    <v-dialog
      v-model="newFieldsToTypeResourceEvent"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              champsOfTypeResourceToAdd &&
              selectedlinkButton.name === 'type_ressources'
                ? $i18n.locale === 'fr'
                  ? 'Ajouter un champ pour le type de ressources'
                  : 'Add resource type field'
                : $i18n.locale === 'fr'
                ? "Ajouter un champ pour le type d'événement"
                : 'Add Event type field'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('newFieldsToTypeResourceEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="newFieldsToTypeResourceEvent" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="champsOfTypeResourceToAdd.name"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                  "
                  dense
                  clearable
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                    })
                  "
                  v-model="champsOfTypeResourceToAdd.field_type"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                    })
                  "
                  item-text="label"
                  item-value="id"
                  @change="changeTypeChamps"
                  :items="getAllTypescustomFields"
                  outlined
                  :persistent-placeholder="true"
                  required
                  return-object
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      $t('msgObligMsg', {
                        msg:
                          $i18n.locale === 'fr' ? 'Type du champ' : 'Field type'
                      })
                  ]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="visibilityOption(champsOfTypeResourceToAdd.field_type)"
            >
              <v-col>
                <v-text-field
                  label="Options"
                  dense
                  ref="refInputOptionResource"
                  :disabled="getCustomFieldsLoading"
                  @click:append-outer="
                    addOptionsAddTypeResource(champsOfTypeResourceToAdd)
                  "
                  @click:clear="
                    addOptionsAddTypeResource(champsOfTypeResourceToAdd)
                  "
                  @keyup.enter="
                    addOptionsAddTypeResource(champsOfTypeResourceToAdd)
                  "
                  append-outer-icon="mdi-send"
                  :persistent-placeholder="true"
                  outlined
                  v-model="champsOfTypeResourceToAdd.nameOption"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      (champsOfTypeResourceToAdd.selectedOption &&
                        champsOfTypeResourceToAdd.selectedOption.length > 0) ||
                      'Options ' + $t('msgOblg')
                  ]"
                >
                </v-text-field>
                <Container
                  group-name="col"
                  @drop="onCardDropAddCustomFieldOptionTypeRessource($event)"
                  drag-class="card-ghost"
                  drop-class="card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                  :lock-axis="'y'"
                  :class="'hello-002'"
                  drag-handle-selector=".column-drag-container "
                >
                  <Draggable
                    v-for="(item,
                    index) in champsOfTypeResourceToAdd.selectedOption"
                    :key="index"
                  >
                    <v-text-field
                      class="mt-2"
                      :label="'Option ' + `${index + 1}`"
                      dense
                      :disabled="getCustomFieldsLoading"
                      @click:append-outer="
                        addOptionsDeleteTypeResource(item.id)
                      "
                      @click:clear="addOptionsDeleteTypeResource(item.id)"
                      append-outer-icon="mdi-trash-can-outline"
                      :persistent-placeholder="true"
                      outlined
                      v-model="item.name"
                      color="#5C2DD3"
                      required
                      item-color="#5C2DD3"
                      validate-on-blur
                      :rules="[v => !!v || 'Options ' + $t('msgOblg')]"
                    >
                      <template #prepend>
                        <v-icon
                          :class="{
                            'column-drag-container':
                              champsOfTypeResourceToAdd &&
                              champsOfTypeResourceToAdd.selectedOption &&
                              champsOfTypeResourceToAdd.selectedOption.length >
                                1
                          }"
                          >mdi-drag-horizontal-variant</v-icon
                        >
                      </template>
                    </v-text-field>
                  </Draggable>
                </Container>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- TYPE RESOURCE -->
                <v-autocomplete
                  v-if="selectedlinkButton.name === 'type_ressources'"
                  color="#5C2DD3"
                  multiple
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="champsOfTypeResourceToAdd.section"
                  :items="getTypeResource"
                  :persistent-placeholder="true"
                  :label="
                    $i18n.locale === 'fr' ? 'Type ressources' : 'Resource type'
                  "
                  item-text="designation"
                  dense
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
                <!-- TYPE EVENT -->
                <v-autocomplete
                  v-if="selectedlinkButton.name === 'type_events'"
                  color="#5C2DD3"
                  multiple
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr' ? 'type événement' : 'event type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="champsOfTypeResourceToAdd.section"
                  :items="getTypeEvent"
                  :persistent-placeholder="true"
                  :label="
                    $i18n.locale === 'fr' ? 'Type événements' : 'event type'
                  "
                  item-text="designation"
                  dense
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr' ? 'type événement' : 'event type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              v-if="
                champsOfTypeResourceToAdd.section &&
                  champsOfTypeResourceToAdd.section.length
              "
            >
              <v-col>
                <div
                  v-for="(typeResource,
                  index) in champsOfTypeResourceToAdd.section"
                  :key="index"
                  class="block-fields"
                >
                  <v-row class="mb-1 mt-1">
                    <v-col class="col-ss-categ ml-1">
                      <div class="field-name">
                        {{
                          typeResource && typeResource.designation
                            ? typeResource.designation
                            : ''
                        }}
                      </div></v-col
                    >
                    <v-col class="col-ss-categ">
                      <v-switch
                        class="input-checkbox switch-bottom label-switch float-right"
                        :label="
                          $i18n.locale === 'fr'
                            ? 'Champ obligatoire'
                            : 'Required field'
                        "
                        color="#5C2DD3"
                        hide-details=""
                        v-model="champsOfTypeResourceToAdd.required[index]"
                      >
                      </v-switch>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCustomFieldsLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCustomFieldsError" class="error-msg">
              <ul v-if="Array.isArray(getCustomFieldsError)">
                <li v-for="(e, index) in getCustomFieldsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCustomFieldsError }}</span>
            </div>
          </div>
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleCustomFieldsTypeResource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('newFieldsToTypeResourceEvent')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Container, Draggable } from 'vue-smooth-dnd'
export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      ModaladdChamps: false,
      model: 'tab-0',
      champsToAdd: {
        name: null,
        field_type: null,
        options: [],
        selectedOption: [],
        nameOption: null,
        order: null,
        section: [],
        sub_type: [],
        sub_categories: [],
        sub_categories_entet: []
      },
      champsOfTypeResourceToAdd: {
        name: null,
        field_type: null,
        options: [],
        selectedOption: [],
        nameOption: null,
        order: null,
        section: [],
        required: []
      },
      id_menu: null,
      initLoading: true,
      selectedlink: 'contacts',
      selectedlinkButton: {},
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      loading: false,
      error: null,
      newFieldsToTypeResourceEvent: false
    }
  },
  methods: {
    ...mapActions([
      'addCustomFields',
      'fetchAllCustomFields',
      'resetErrorCustomField',
      'fetchListCategorieInTable',
      'fetchSubCategorieForCustemFields',
      'fetchAllTypeResource',
      'fetchAllTypeEvent',
      'fetchAllTablesCustomFields'
    ]),
    changeTypeChamps() {
      this.champsOfTypeResourceToAdd.selectedOption = []
    },
    closeDialog(ref) {
      this[ref] = false
      this.error = null
      if (ref == 'ModaladdChamps') {
        this.$refs.ModaladdChamps.resetValidation()
      }
      if (ref == 'newFieldsToTypeResourceEvent') {
        this.$refs.newFieldsToTypeResourceEvent.resetValidation()
      }
      this.resetModal()
    },
    resetModal() {
      this.champsToAdd = {
        name: null,
        field_type: null,
        options: [],
        selectedOption: [],
        nameOption: null,
        order: null,
        sub_type: [],
        section: [],
        sub_categories: [],
        sub_categories_entet: []
      }
      this.champsOfTypeResourceToAdd = {
        name: null,
        field_type: null,
        options: [],
        selectedOption: [],
        nameOption: null,
        order: null,
        section: [],
        required: []
      }
      this.id_menu = null
      this.resetErrorCustomField()
    },
    async Menu(linkOffre) {
      this.selectedlink = linkOffre.name
      this.selectedlinkButton = linkOffre
    },
    async Drapdownbutton() {
      if (
        this.selectedlinkButton.name == 'contacts' ||
        this.selectedlinkButton.name == 'projets'
      ) {
        this.newFieldsToTypeResourceEvent = false
        this.ModaladdChamps = true
        await this.fetchListCategorieInTable({
          idTable: this.selectedlinkButton
        })
      }
      if (
        this.selectedlinkButton.name == 'type_ressources' ||
        this.selectedlinkButton.name == 'type_events'
      ) {
        this.ModaladdChamps = false
        this.newFieldsToTypeResourceEvent = true
        if (this.selectedlinkButton.name == 'type_ressources') {
          if (!this.getTypeResource || !this.getTypeResource.length) {
            this.fetchAllTypeResource()
          }
        }
        if (this.selectedlinkButton.name == 'type_events') {
          if (!this.getTypeEvent || !this.getTypeEvent.length) {
            this.fetchAllTypeEvent()
          }
        }
      }
    },
    async changeCategorieInAddModal($event) {
      this.error = null
      await this.fetchSubCategorieForCustemFields({
        event: $event
      })
    },
    addOptionsAdd(click) {
      this.$refs.refInputOption.resetValidation()
      if (
        click &&
        !click.nameOption &&
        click.options &&
        (!click.options.data || !click.options.data.length)
      ) {
        this.error =
          this.$i18n.locale === 'fr'
            ? 'Le champ option doit être rempli obligatoirement'
            : 'The option field must be completed'

        return
      }
      this.error = null
      let count = this.champsToAdd ? this.champsToAdd.selectedOption.length : 0
      this.champsToAdd.selectedOption.push({
        name: click.nameOption,
        id: count++,
        order: count++
      })
      this.champsToAdd.nameOption = null
    },
    addOptionsAddTypeResource(click) {
      this.$refs.refInputOptionResource.resetValidation()
      if (
        click &&
        !click.nameOption &&
        click.options &&
        (!click.options.data || !click.options.data.length)
      ) {
        this.error =
          this.$i18n.locale === 'fr'
            ? "Le champ 'Option' doit obligatoirement être rempli"
            : "The 'Option' field must be filled in"

        return
      }
      this.error = null
      let count = this.champsOfTypeResourceToAdd
        ? this.champsOfTypeResourceToAdd.selectedOption.length
        : 0
      this.champsOfTypeResourceToAdd.selectedOption.push({
        name: click.nameOption,
        id: count++,
        order: count++
      })
      this.champsOfTypeResourceToAdd.nameOption = null
    },
    addOptionsDeleteTypeResource(value) {
      this.error = null
      let key = false
      for (
        let index = 0;
        index < this.champsOfTypeResourceToAdd.selectedOption.length;
        index++
      ) {
        const option = this.champsOfTypeResourceToAdd.selectedOption[index]
        if (option.id == value) {
          key = index
          break
        }
      }
      if (key !== false) {
        this.champsOfTypeResourceToAdd.selectedOption.splice(key, 1)
      }
    },
    addOptionsDelete(value) {
      this.error = null
      let key = false
      for (
        let index = 0;
        index < this.champsToAdd.selectedOption.length;
        index++
      ) {
        const option = this.champsToAdd.selectedOption[index]
        if (option.id == value) {
          key = index
          break
        }
      }
      if (key !== false) {
        this.champsToAdd.selectedOption.splice(key, 1)
      }
    },
    async handleSubmitAddCustomFields() {
      if (this.$refs.ModaladdChamps.validate()) {
        let validation = []
        if (
          this.champsToAdd.sub_categories &&
          this.champsToAdd.sub_categories.length
        ) {
          validation = this.champsToAdd.sub_categories.filter(
            item => item != null
          )
        }
        if (
          this.champsToAdd.section &&
          this.champsToAdd.section.length &&
          (!this.champsToAdd.sub_categories ||
            !this.champsToAdd.sub_categories.length ||
            !validation ||
            !validation.length)
        ) {
          this.error =
            this.$i18n.locale === 'fr'
              ? 'Il faut choisir au moins une sous-catégorie.'
              : 'You must choose at least one sub-category.'
          return
        }
        this.error = null
        // this.loading = true
        const payload = {
          name: this.champsToAdd.name,
          field_type:
            this.champsToAdd.field_type && this.champsToAdd.field_type.id
              ? this.champsToAdd.field_type.id
              : this.champsToAdd.field_type,
          id: this.selectedlinkButton.id,
          category: this.champsToAdd.section,
          subcategory: []
        }
        if (this.selectedlinkButton.name === 'projets') {
          payload.sub_types = this.champsToAdd.sub_type.map(item => {
            return item.id
          })
        }
        if (
          this.champsToAdd.sub_categories &&
          this.champsToAdd.sub_categories.length
        ) {
          for (let i = 0; i < this.champsToAdd.sub_categories.length; i++) {
            if (
              this.champsToAdd.sub_categories[i] !== null &&
              this.champsToAdd.sub_categories[i] !== undefined
            ) {
              payload.subcategory.push({
                subcategory_id: this.champsToAdd.sub_categories[i],
                is_subcategory_header: this.champsToAdd.sub_categories_entet[i]
                  ? 1
                  : 0
              })
            }
          }
        }

        const tabOptions = []
        for (
          let index = 0;
          index < this.champsToAdd.selectedOption.length;
          index++
        ) {
          if (this.champsToAdd.selectedOption[index]) {
            tabOptions.push(this.champsToAdd.selectedOption[index].name)
          }
        }
        payload.options = tabOptions
        const response = await this.addCustomFields(payload)
        if (response) {
          this.closeDialog('ModaladdChamps')
          this.computedCustomFieldsMenu.forEach(c => {
            if (c.name == this.selectedlink) {
              this.id_menu = c.id
              this.fetchAllCustomFields(this.id_menu)
            }
          })
        }
        this.loading = false
      }
    },
    async handleCustomFieldsTypeResource() {
      if (this.$refs.newFieldsToTypeResourceEvent.validate()) {
        this.error = null
        this.loading = true
        const payload = {
          name: this.champsOfTypeResourceToAdd.name,
          field_type:
            this.champsOfTypeResourceToAdd.field_type &&
            this.champsOfTypeResourceToAdd.field_type.id
              ? this.champsOfTypeResourceToAdd.field_type.id
              : this.champsOfTypeResourceToAdd.field_type,
          id: this.selectedlinkButton.id,
          type_ressources: []
        }

        for (
          let i = 0;
          i < this.champsOfTypeResourceToAdd.section.length;
          i++
        ) {
          if (
            this.champsOfTypeResourceToAdd.section[i] !== null &&
            this.champsOfTypeResourceToAdd.section[i] !== undefined
          ) {
            payload.type_ressources.push({
              id:
                this.champsOfTypeResourceToAdd.section[i] &&
                this.champsOfTypeResourceToAdd.section[i].id
                  ? this.champsOfTypeResourceToAdd.section[i].id
                  : this.champsOfTypeResourceToAdd.section[i],
              required: this.champsOfTypeResourceToAdd.required[i] ? 1 : 0
            })
          }
        }

        const tabOptions = []
        for (
          let index = 0;
          index < this.champsOfTypeResourceToAdd.selectedOption.length;
          index++
        ) {
          if (this.champsOfTypeResourceToAdd.selectedOption[index]) {
            tabOptions.push(
              this.champsOfTypeResourceToAdd.selectedOption[index].name
            )
          }
        }
        payload.selectedlinkButton = this.selectedlinkButton.name
        payload.options = tabOptions
        const response = await this.addCustomFields(payload)
        if (response) {
          this.closeDialog('newFieldsToTypeResourceEvent')
        }
        this.loading = false
      }
    },
    onCardDropAddCustomFieldOption(dropResult) {
      this.error = null
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.champsToAdd.selectedOption = this.applyDrag(
        this.champsToAdd.selectedOption,
        dropResult
      )
    },
    onCardDropAddCustomFieldOptionTypeRessource(dropResult) {
      this.error = null
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.champsOfTypeResourceToAdd.selectedOption = this.applyDrag(
        this.champsOfTypeResourceToAdd.selectedOption,
        dropResult
      )
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    }
  },
  computed: {
    ...mapGetters([
      'getAllTypescustomFields',
      'getCustomFieldsMenu',
      'getCustomFieldsLoading',
      'getCustomFieldsError',
      'getListCategorieInTable',
      'getCustemFieldsSubCategorieList',
      'getAllSubTypes',
      'getTypeResource',
      'getTypeEvent'
    ]),
    computedCustomFieldsMenu() {
      if (this.getCustomFieldsMenu && this.getCustomFieldsMenu.length) {
        return this.getCustomFieldsMenu.map(item => {
          item.url =
            item.name == 'contacts'
              ? '/setting/champs-personnalises/contact'
              : item.name == 'projets'
              ? '/setting/champs-personnalises/projet'
              : item.name == 'type_ressources'
              ? '/setting/champs-personnalises/type_ressources'
              : '/setting/champs-personnalises/type_events'

          return item
        })
      }
      return []
    },
    visibilityOption() {
      return function(data) {
        if (
          data &&
          data.type &&
          //  id type tag
          ((data.type == 'set' && data.id != 51) ||
            data.type == 'enum' ||
            data.type == 'one_select' ||
            data.type == 'checkbox')
        ) {
          return true
        } else {
          return false
        }
      }
    }
  },
  filters: {
    translateName: function(name, locale) {
      switch (name) {
        case 'projets':
          return locale === 'fr' ? 'Projets' : 'Projects'
        case 'contacts':
          return locale === 'fr' ? 'Contacts' : 'Contacts'
        case 'type_ressources':
          return locale === 'fr' ? 'Type ressources' : 'Resource type'
        case 'type_events':
          return locale === 'fr' ? 'Type événements' : 'Event type'
      }
      return ''
    }
  },
  async mounted() {
    await this.fetchAllTablesCustomFields({
      customizable: 1,
      visibility: 1
    })
    if (
      this.getCustomFieldsMenu &&
      this.getCustomFieldsMenu.length &&
      this.$route &&
      this.$route.path
    ) {
      // Utilisez la méthode find pour trouver le premier élément dont l'URL correspond à $route.path
      const matchingItem = this.getCustomFieldsMenu.find(
        item => item.url === this.$route.path
      )

      // Si un élément correspondant est trouvé, mettez à jour selectedlinkButton
      if (matchingItem) {
        this.selectedlinkButton = matchingItem
      }
    }
  }
}
</script>
<style lang="scss">
.col-ss-categ {
  padding: 4px 12px !important;
}
</style>
<style lang="scss" scoped>
.fields-projet-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}
</style>
